import React, { Component } from "react";
import CommonTable from "../../../Common/Table/Table";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  MenuItem,
} from "@mui/material";
import Loader from "../../../Common/loader/containers/loader_cont";
import Snackbar from "../../../Common/snackbar/components/snackbar";
import moment from "moment";

function withNavigation(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    return (
      <Component
        {...props}
        navigate={navigate}
        location={location}
        params={params}
      />
    );
  };
}

const data = [
  {
    id: 1,
    name: "John Doe",
    age: 28,
    email: "john.doe@example.com",
    whatsAppSms_id: "EMP001",
    status: "Active",
  },
  {
    id: 2,
    name: "Jane Smith",
    age: 34,
    email: "jane.smith@example.com",
    whatsAppSms_id: "EMP002",
    status: "Active",
  },
  {
    id: 3,
    name: "Sam Green",
    age: 23,
    email: "sam.green@example.com",
    whatsAppSms_id: "EMP003",
    status: "Active",
  },
];

const TableWithNavigation = ({ data, columns, props, unique }) => {
  const navigate = useNavigate();

  //   const handleRowClick = (unique) => {
  //     props.setWhatsAppSmsId(unique);
  //     navigate(`/WhatsAppSmsClients`);
  //   };

  return (
    <CommonTable
      data={data}
      columns={columns}
      //   onRowClick={handleRowClick}
      unique={unique}
    />
  );
};

class WhatsAppSms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,

      columns: [
        { header: "Name", key: "name" },
        { header: "Mobile", key: "mobile" },
        { header: "Email", key: "email" },
        { header: "Model", key: "modal" },
        { header: "Enquiry Type", key: "enquirytype" },

        { header: "Action", key: "action" },
      ],

      mobcolumns: [
        { header: "Name", key: "name" },
        { header: "Action", key: "action" },
      ],
      whatsAppSms_id: "",
      mobile: "",
      email: "",
      name: "",
      modal: "",
      color: "",
      variant: "",
      address: "",
      salesExecutiveName: "",
      enquirytype: "",
      date: "",
      add: false,
      edit: false,
      view: false,
      deleteM: false,
      page: 1,
      pageSize: 10,
      user_id: 0,
      isMobileView: window.innerWidth <= 768,
      message_tempelete: "", // Initial check for mobile view
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    this.props.viewWhatsAppSms(
      this.props.login.token,
      this.state.page,
      this.state.pageSize
    );
    this.props.getmessageTemplete(
      this.props.login.token,
      this.props.login.user_id
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobileView: window.innerWidth <= 768 });
  };

  renderName = (addedUser) => {
    const { isMobileView } = this.state;

    if (!isMobileView) {
      return <span>{addedUser?.name}</span>;
    } else {
      return (
        <>
          <span>{addedUser?.name}</span>
          <br />
          <span>{addedUser?.mobile}</span>
          <br />
          <span>{addedUser?.whatsAppSms_id}</span>
          <br />
        </>
      );
    }
  };

  processData = (data) => {
    return (
      Array.isArray(data) &&
      data.map((e) => ({
        ...e,
        name: this.renderName(e),
        status: <span>{"Active"}</span>,
        action: (
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item>
              <Button
                className="table-text"
                style={{
                  color: "rgb(20, 28, 76)",
                  backgroundColor: "rgba(77, 85, 124, 0.3)",
                  textTransform: "capitalize",
                  border: "1px solid rgb(20, 28, 76)",
                  padding: 0,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  this.setState({
                    view: true,
                    whatsAppSms_id: e._id,
                    name: e.name,
                    email: e.email,
                    mobile: e.mobile,
                    modal: e.modal,
                    color: e.color,
                    variant: e.variant,
                    address: e.address,
                    salesExecutiveName: e.salesExecutiveName,
                    enquirytype: e.enquirytype,
                    message_tempelete: e.message_tempelete,
                    date: moment(e.updatedAt).format("DD/MM/YYYY,HH:MM"),
                  });
                }}
              >
                View Details
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                className="table-text"
                style={{
                  color: "rgb(212, 44, 44)",
                  backgroundColor: "rgba(220, 85, 85, 0.3)",
                  textTransform: "capitalize",
                  border: "1px solid rgb(212, 44, 44)",
                  padding: 0,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  this.setState({
                    deleteM: true,
                    whatsAppSms_id: e._id,
                    name: e.name,
                  });
                }}
              >
                Delete
              </Button>
            </Grid> */}
          </Grid>
        ),
      }))
    );
  };

  processColumn = (columns) => {
    const { isMobileView } = this.state;
    if (isMobileView) {
      // Return mobile columns
      return this.state.mobcolumns.map((e) => ({
        ...e,
        action: "action",
      }));
    } else {
      // Return desktop columns
      return columns.map((e) => ({
        ...e,
        action: "action",
      }));
    }
  };

  render() {
    const handleChangePage = (event, newPage) => {
      this.props.viewWhatsAppSms(
        this.props.login.token,
        newPage,
        this.state.pageSize
      );
      this.setState({ page: newPage });
    };

    const {
      whatsAppSms_id,
      mobile,
      email,
      name,
      add,
      edit,
      view,
      deleteM,
      user_id,
      modal,
      variant,
      color,
      address,
      enquirytype,
      salesExecutiveName,
      date,
      message_tempelete,
    } = this.state;
    const newdata = this.processData(this.props.exec?.all_sms);
    const newcolumns = this.processColumn(this.state.columns);

    return (
      <Grid padding={1}>
        <Grid display={"flex"} justifyContent={"flex-end"}>
          <Grid pb={1}>
            <Button
              className="table-text"
              style={{
                backgroundColor: "rgb(20, 28, 76)",
                color: "white",
                textTransform: "capitalize",
              }}
              onClick={() => {
                this.setState({
                  add: true,
                  whatsAppSms_id: "",
                  name: "",
                  email: "",
                  mobile: "",
                  modal: "",
                  variant: "",
                  color: "",
                  address: "",
                  salesExecutiveName: "",
                  enquirytype: "",
                  message_tempelete: "",
                });
              }}
            >
              Add Enquiry
            </Button>
          </Grid>
        </Grid>

        <Card>
          <TableWithNavigation
            data={newdata}
            columns={newcolumns}
            props={this.props}
            unique={"_id"}
          />
        </Card>
        {/* <Pagination
          style={{ display: "flex", justifyContent: "center", padding: "10px" }}
          count={this.props.exec?.sms_length}
          size="small"
          shape="rounded"
          onChange={handleChangePage}
        /> */}

        {/* /-------------------------------- ADD ------------------------------------/ */}
        <Dialog
          maxWidth="md"
          fullWidth
          open={add}
          onClose={() => {
            this.setState({
              add: false,
            });
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // Combine +91 with the user input
              // const mobileWithPrefix = `91${this.state.mobile}`;
              this.props.addWhatsAppSms(
                this.props.login.token,
                this.props.login.user_id,
                name,
                email,
                mobile,
                modal,
                variant,
                color,
                address,
                salesExecutiveName,
                enquirytype,
                message_tempelete
              );
              this.setState({
                add: false,
                name: "",
                email: "",
                mobile: "",
                modal: "",
                variant: "",
                color: "",
                address: "",
                salesExecutiveName: "",
                enquirytype: "",
                message_tempelete: "",
              });
            }}
          >
            <DialogTitle backgroundColor={"rgba(146, 144, 195, 0.2)"}>
              <Typography
                variant="h5"
                className="table-text2"
                style={{ color: "rgb(20, 28, 76)" }}
              >
                Add Enquirey Register
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={0}>
                {/* Name */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Name<span style={{ color: "red" }}>*</span>:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <TextField
                        required
                        type="text"
                        fullWidth
                        size="small"
                        placeholder="Name"
                        onChange={(event) => {
                          this.setState({ name: event.target.value });
                        }}
                        value={name}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Enquiry Type<span style={{ color: "red" }}>*</span>:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <TextField
                        required
                        select
                        fullWidth
                        size="small"
                        value={this.state.enquirytype}
                        onChange={(event) =>
                          this.setState({ enquirytype: event.target.value })
                        }
                      >
                        <MenuItem value="Walk-in">Walk-in</MenuItem>
                        <MenuItem value="Tele-in">Tele-in</MenuItem>
                        <MenuItem value="Digital">Digital</MenuItem>
                        <MenuItem value="Hyperlocal">Hyperlocal</MenuItem>
                        <MenuItem value="Event">Event</MenuItem>
                        <MenuItem value="Refferal">Refferal</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={0} spacing={2}>
                {/* Mobile */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Mobile No.<span style={{ color: "red" }}>*</span>:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <TextField
                        required
                        type="text" // Change type to "text" to allow input without restricting to numbers
                        fullWidth
                        size="small"
                        placeholder="Mobile No."
                        onChange={(event) => {
                          const value = event.target.value;
                          // Ensure user can only input 10 digits and doesn't enter invalid characters
                          if (/^\d*$/.test(value)) {
                            this.setState({ mobile: value });
                          }
                        }}
                        value={this.state.mobile} // Value is managed by state
                        InputProps={{
                          // Fixed +91 prefix
                          startAdornment: (
                            <span style={{ marginRight: "5px" }}>+91</span>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Email */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Email ID:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <TextField
                        type="email"
                        fullWidth
                        size="small"
                        placeholder="Email ID"
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                        value={email}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={0} spacing={2} alignItems="center">
                {/* Model (Dropdown) */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize="20px"
                      >
                        Model<span style={{ color: "red" }}>*</span>:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        required
                        select
                        fullWidth
                        size="small"
                        value={this.state.modal}
                        onChange={(event) =>
                          this.setState({ modal: event.target.value })
                        }
                      >
                        <MenuItem value="Amaze">Amaze</MenuItem>
                        <MenuItem value="New-Amaze">New-Amaze</MenuItem>
                        <MenuItem value="City">City</MenuItem>
                        <MenuItem value="Citye-HEV">Citye-HEV</MenuItem>
                        <MenuItem value="Elevate">Elevate</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Message Template (Dropdown) */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems="center">
                    <Grid item xs={12} sm={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize="20px"
                      >
                        Message Template<span style={{ color: "red" }}>*</span>:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <TextField
                        required
                        select
                        fullWidth
                        size="small"
                        value={this.state.message_tempelete}
                        onChange={(event) =>
                          this.setState({
                            message_tempelete: event.target.value,
                          })
                        }
                      >
                        {this.props.exec.message_tempelete?.map(
                          (item, index) => (
                            <MenuItem key={index} value={item.name}>
                              {item.name}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={0} spacing={2}>
                {/* Color (Dropdown) */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Color:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        value={this.state.color}
                        onChange={(event) =>
                          this.setState({ color: event.target.value })
                        }
                      >
                        <MenuItem value="Platinum White Pearl">
                          Platinum White Pearl
                        </MenuItem>
                        <MenuItem value="Lunar Siliver Metallic">
                          Lunar Siliver Metallic
                        </MenuItem>
                        <MenuItem value="Meteoroid Gray Metallic">
                          Meteoroid Gray Metallic
                        </MenuItem>
                        <MenuItem value="Golden Brown Metallic">
                          Golden Brown Metallic
                        </MenuItem>
                        <MenuItem value="Radiant Red Metallic">
                          Radiant Red Metallic
                        </MenuItem>
                        <MenuItem value="Obsidian Blue Pearl">
                          Obsidian Blue Pearl
                        </MenuItem>
                        <MenuItem value="Phoenix Orange Pearl">
                          Phoenix Orange Pearl
                        </MenuItem>
                        <MenuItem value="Phoenix Orange Pearl with Crystal Black Pearl Roof">
                          Phoenix Orange Pearl with Crystal Black Pearl Roof
                        </MenuItem>
                        <MenuItem value="Radiant Red Metallic Crystal Black Pearl Roof">
                          Radiant Red Metallic Crystal Black Pearl Roof
                        </MenuItem>
                        <MenuItem value="Platinum White Pearl Crystal Black Pearl Roof">
                          Platinum White Pearl Crystal Black Pearl Roof
                        </MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Sales Executive Name */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Sales Executive Name:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <TextField
                        type="text"
                        fullWidth
                        size="small"
                        placeholder="Sales Executive Name"
                        onChange={(event) =>
                          this.setState({
                            salesExecutiveName: event.target.value,
                          })
                        }
                        value={this.state.salesExecutiveName}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={0} spacing={2}>
                {/* Variant (Dropdown) */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Variant:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <TextField
                        select
                        fullWidth
                        size="small"
                        value={this.state.variant}
                        onChange={(event) =>
                          this.setState({ variant: event.target.value })
                        }
                      >
                        <MenuItem value="V">V</MenuItem>
                        <MenuItem value="SV">SV</MenuItem>
                        <MenuItem value="VX">VX</MenuItem>
                        <MenuItem value="ZX">ZX</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                {/* Address */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Address:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <TextField
                        type="text"
                        multiline
                        rows={3}
                        fullWidth
                        size="small"
                        placeholder="Address"
                        onChange={(event) =>
                          this.setState({ address: event.target.value })
                        }
                        value={this.state.address}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ padding: "15px" }}>
              <Button
                style={{
                  backgroundColor: "rgb(20, 28, 76)",
                  color: "white",
                  textTransform: "capitalize",
                }}
                type="submit"
              >
                Submit
              </Button>
              <Button
                style={{
                  backgroundColor: "rgb(20, 28, 76)",
                  color: "white",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  this.setState({
                    add: false,
                    name: "",
                    email: "",
                    mobile: "",
                    modal: "",
                    variant: "",
                    color: "",
                    address: "",
                    salesExecutiveName: "",
                  });
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        {/* /-------------------------------- VIEW ------------------------------------/ */}
        <Dialog
          maxWidth="md"
          fullWidth
          open={view}
          onClose={() => {
            this.setState({
              view: false,
            });
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={"rgba(146, 144, 195, 0.2)"}>
            <Typography
              variant="h5"
              className="table-text2"
              style={{ color: "rgb(20, 28, 76)" }}
            >
              View Enquirey Register Details
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={0}>
              {/* Name */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Name:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">{name || "N/A"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Enquiry Type */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Enquiry Type:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">
                      {enquirytype || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={0} spacing={2}>
              {/* Mobile */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Mobile No.:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">
                      +91 {mobile || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Email */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Email ID:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">{email || "N/A"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={0} spacing={2}>
              {/* Modal */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Modal:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">
                      {this.state.modal || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Variant */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Message Template:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">
                      {this.state.message_tempelete || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={0} spacing={2}>
              {/* Color */}
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Color:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">
                      {this.state.color || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Sales Executive Name:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">
                      {this.state.salesExecutiveName || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {/* Address */}
            </Grid>

            {/* Sales Executive Name */}
            <Grid container mt={0} spacing={2}>
              <Grid item xs={12} sm={6}>
                {/* Variant */}
                <Grid item xs={12} sm={6}>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} sm={12} md={4}>
                      <DialogContentText
                        className="table-text2"
                        fontSize={"20px"}
                      >
                        Variant:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8}>
                      <Typography variant="body1">
                        {this.state.variant || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Address:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">
                      {this.state.address || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={"center"}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText
                      className="table-text2"
                      fontSize={"20px"}
                    >
                      Date & time:
                    </DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="body1">
                      {this.state.date || "N/A"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions style={{ padding: "15px" }}>
            <Button
              style={{
                backgroundColor: "rgb(20, 28, 76)",
                color: "white",
                textTransform: "capitalize",
              }}
              onClick={() => {
                this.setState({
                  view: false,
                });
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* /-------------------------------- DELETE ------------------------------------/ */}
        <Dialog
          maxWidth="xs"
          fullWidth
          open={deleteM}
          onClose={() => {
            this.setState({
              eddeleteMit: false,
            });
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={"rgba(146, 144, 195, 0.2)"}>
            <Typography
              variant="h5"
              className="table-text2"
              style={{ color: "rgb(20, 28, 76)" }}
            >
              Delete Employee
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              Are you sure you want to delete this employee{" "}
              <span style={{ fontWeight: 600 }}>{name}</span> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                this.props.deleteWhatsAppSms(
                  this.props.login.token,
                  whatsAppSms_id
                );
                this.setState({ deleteM: false });
              }}
              style={{
                backgroundColor: "rgb(212, 44, 44)",
                color: "white",
                textTransform: "capitalize",
              }}
            >
              Delete
            </Button>
            <Button
              style={{
                backgroundColor: "rgb(20, 28, 76)",
                color: "white",
                textTransform: "capitalize",
              }}
              onClick={() => {
                this.setState({
                  deleteM: false,
                });
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Loader />
        {/* <Snackbar
          open={this.props.snackbar.response_received}
          close_snack_bar={this.props.close_snack_bar}
          message={this.props.snackbar.message}
        /> */}
      </Grid>
    );
  }
}

export default withNavigation(WhatsAppSms);

import React, { Component } from 'react';
import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import OpenInFullTwoToneIcon from '@mui/icons-material/OpenInFullTwoTone';
import TimerTwoToneIcon from '@mui/icons-material/TimerTwoTone';
import Fab from '@mui/material/Fab';
import DriveIcon from '@mui/icons-material/DriveEta';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import MapComponent from './MapComponent';
import TravelTracker from './TravelTracker';
import Loader from '../../../Common/loader/containers/loader_cont';
import Snackbar from "../../../Common/snackbar/components/snackbar";
import * as XLSX from 'xlsx';
import NoSleep from 'nosleep.js';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} location={useLocation()} />;
}
class ClientDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noSleep: new NoSleep(),
      emp_id: '',
      mobile: '',
      email: '',
      name: '',
      add: false,
      edit: false,
      deleteM: false,
      page: 1,
      pageSize: 10,
      user_id: 0,
      model_name: '',
      v_no: '',
      otp_no: '',
      latitude: '',
      longitude: '',
      location: {},
      start_loc: {},
      end_loc: {},
      map: false,
      watchId: null,
      isUpdating: false,
      visibleMapIndex: null,
      mapindex: '',
      edit: false
    }
  }
  handleClick = () => {
    this.setState({
      add: true
    })
  };
  handleLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            location: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              accuracy: position.coords.accuracy,
              altitude: position.coords.altitude,
              altitudeAccuracy: position.coords.altitudeAccuracy,
              heading: position.coords.heading,
              speed: position.coords.speed,
            },
          });

        },
        (error) => {
          console.error('Error accessing location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }
  startUpdate = () => {
    this.setState({ isUpdating: true });
    this.interval = setInterval(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.setState({
              location: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            });
            this.props.viewTravelRecord(this.props.login.token, this.props.client.check_start._id);
            this.props.updateTestDrive(this.props.login.token, this.props.params.id, this.props.client.check_start._id, position.coords.longitude, position.coords.latitude);

          },
          (error) => {
            console.error('Error accessing location:', error);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    }, 5000);
  };
  stopUpdate = () => {
    clearInterval(this.interval);
    this.setState({ isUpdating: false });
  };
  watchLocation = () => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
        },
        (error) => {
          console.error('Error watching position', error);
        },
        { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
      );

      this.setState({ watchId }); // Save the watch ID
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  };
  formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = parseInt(remainingSeconds).toString().padStart(2, '0');

    return `${formattedHours}.${formattedMinutes}.${formattedSeconds}`;
  }
  componentDidMount() {
    this.props.viewModel(this.props.login.token);
    this.props.viewClientDetails(this.props.login.token, this.props.params.id);
    this.props.viewClientTestDrive(this.props.login.token, this.props.params.id);
    this.startUpdateIfNeeded(this.props.client.check_start);
    console.log(this.props.client.drive_record)
  }
  componentDidUpdate(prevProps, prevState) {
    const prevCheckStart = prevProps.client.check_start;
    const currentCheckStart = this.props.client.check_start;

    // Start update if necessary
    if (prevCheckStart !== currentCheckStart) {
      this.startUpdateIfNeeded(currentCheckStart);
    }
  }
  componentWillUnmount() {
    this.stopUpdate();
  }
  startUpdateIfNeeded = (checkStart) => {
    if (checkStart?._id !== undefined && checkStart.status !== "end") {
      if (!this.state.isUpdating) {
        this.startUpdate();
      }
    } else {
      this.stopUpdate();
    }
  };

  // toggleMapVisibility = (index) => {
  //   this.setState((prevState) => ({
  //     showMap: {
  //       ...prevState.showMap,
  //       [index]: !prevState.showMap[index],
  //     }
  //   }));
  // };


  enableNoSleep = () => {
    this.state.noSleep.enable();
  };

  disableNoSleep = () => {
    this.state.noSleep.disable();
  };

  toggleMapVisibility = (index) => {
    if (this.state.visibleMapIndex === index) {
      this.disableNoSleep();
      this.setState({ visibleMapIndex: null });
    } else {
      this.enableNoSleep();
      this.setState({ visibleMapIndex: index });
    }
  };

  exportToExcel = (data, fileName) => {
    // Transform the nested data into a flat structure
    const transformedData = data.map(item => {
      const {
        startPlaceName = "Unknown location",
        endPlaceName = "Unknown location",
        testDrive
      } = item;

      const {
        start_location: { coordinates: startCoordinates = [null, null] } = {},
        end_location: { coordinates: endCoordinates = [null, null] } = {},
        model: { model = "", type = "" } = {},
        taken_by: { name: takenByName = "", email: takenByEmail = "", emp_id: takenById = "", mobile: takenByMobile = "" } = {},
        taken_user: { name: takenUserName = "", email: takenUserEmail = "", mobile: takenUserMobile = "" } = {},
        distance = "",
        travelTime = "",
        start_time = "",
        end_time = ""
      } = testDrive || {};

      return {
        startPlaceName,
        endPlaceName,
        startCoordinates: startCoordinates.join(', '),
        endCoordinates: endCoordinates.join(', '),
        model,
        type,
        distance,
        travelTime,
        start_time,
        end_time,
        takenByName,
        takenByEmail,
        takenById,
        takenByMobile,
        takenUserName,
        takenUserEmail,
        takenUserMobile
      };
    });

    // Define custom headers
    const headers = [
      { v: 'Start Place', s: { font: { bold: true } } },
      { v: 'End Place', s: { font: { bold: true } } },
      { v: 'Start Coordinates', s: { font: { bold: true } } },
      { v: 'End Coordinates', s: { font: { bold: true } } },
      { v: 'Model', s: { font: { bold: true } } },
      { v: 'Type', s: { font: { bold: true } } },
      { v: 'Distance', s: { font: { bold: true } } },
      { v: 'Travel Time', s: { font: { bold: true } } },
      { v: 'Start Time', s: { font: { bold: true } } },
      { v: 'End Time', s: { font: { bold: true } } },
      { v: 'Taken By Name', s: { font: { bold: true } } },
      { v: 'Taken By Email', s: { font: { bold: true } } },
      { v: 'Taken By ID', s: { font: { bold: true } } },
      { v: 'Taken By Mobile', s: { font: { bold: true } } },
      { v: 'Taken User Name', s: { font: { bold: true } } },
      { v: 'Taken User Email', s: { font: { bold: true } } },
      { v: 'Taken User Mobile', s: { font: { bold: true } } }
    ];

    // Convert the data to a worksheet with custom headers
    const worksheetData = [headers.map(header => header.v), ...transformedData.map(item => [
      item.startPlaceName,
      item.endPlaceName,
      item.startCoordinates,
      item.endCoordinates,
      item.model,
      item.type,
      item.distance,
      item.travelTime,
      item.start_time,
      item.end_time,
      item.takenByName,
      item.takenByEmail,
      item.takenById,
      item.takenByMobile,
      item.takenUserName,
      item.takenUserEmail,
      item.takenUserMobile
    ])];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Apply custom styles
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })];
        if (cell) {
          cell.s = {
            border: {
              top: { style: 'thin', color: { rgb: '000000' } },
              bottom: { style: 'thin', color: { rgb: '000000' } },
              left: { style: 'thin', color: { rgb: '000000' } },
              right: { style: 'thin', color: { rgb: '000000' } }
            },
            alignment: {
              vertical: 'center',
              horizontal: 'center'
            },
            font: row === 0 ? { bold: true } : {} // Make headers bold
          };
        }
      }
    }

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the workbook to a file
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };


  render() {
    const { add, model_name, otp_no, otp, location, map, user_id, name, email, mobile, edit } = this.state
    const client_details = this.props.client.client_details
    const test_drive = this.props.client.test_drive;
    const sampleData = this.props.client?.excel
    return (
      <Grid container>
        <Grid item xs={12} padding={2}>
          {Array.isArray(test_drive) && test_drive.length === 0 &&
            <Tooltip title="Start Drive" aria-label="start drive">
              <Fab
                aria-label="start drive"
                sx={{
                  position: 'fixed',
                  top: 90,
                  right: 20,
                  backgroundColor: 'rgb(212, 44, 44)',
                  color: '#fff'
                }}
                onClick={this.handleClick}
              >
                <DriveIcon />
              </Fab>
            </Tooltip>
          }
          <Card style={{ backgroundColor: 'rgba(77, 85, 124, 0.2)' }}>
            <CardContent className="card-content">
              <Grid container justifyContent={'center'} spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={1.5}>
                  {/* <Button className='table-text' style={{ color: "rgb(20, 28, 76)", backgroundColor: "rgba(77, 85, 124, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(20, 28, 76)', padding: 5, height: 30 }}
                    onClick={(event) => {
                      this.props.exportExcel(this.props.params.id);
                    }}
                  >
                    Export Excel
                  </Button> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3.5}>
                  <Typography className="table-text2"><b>Name</b> :{client_details?.name}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3.5}>
                  <Typography className="table-text2"><b>Email</b> : {client_details?.email}</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3.5}>
                  <Typography className="table-text2"><b>Mobile</b> : +91 {client_details?.mobile}</Typography>
                </Grid>
                {Array.isArray(test_drive) && test_drive?.length === 0 &&
                  <Button className='table-text' style={{ color: "rgb(20, 28, 76)", backgroundColor: "rgba(77, 85, 124, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(20, 28, 76)', padding: 0, height: 30, width: 40 }}
                    onClick={(event) => {
                      event.stopPropagation();
                      this.setState({
                        edit: true,
                        user_id: this.props.params.id,
                        name: client_details?.name,
                        email: client_details?.email,
                        mobile: client_details?.mobile,

                      });
                    }}>
                    Edit
                  </Button >
                }
              </Grid>

            </CardContent>
            <Divider style={{ height: 1, backgroundColor: 'rgb(20, 28, 76)' }} />
            <CardContent className="card-content">
              <CardHeader title='Drive Details' />
              <Grid container spacing={2} ml={1} mt={1}>
                {test_drive?.map((detail, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index} p={2}>
                    <div style={{ position: 'relative', display: 'inline-block', width: '100%' }}>
                      <Grid
                        container
                        className='table-text'
                        justifyContent="center"
                        alignItems="center"
                        style={{
                          position: 'absolute',
                          top: '-20px',
                          left: '-20px',
                          border: '1px solid black',
                          borderRadius: '50%',
                          width: '40px',
                          height: '40px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: 'rgb(20, 28, 76)',
                          fontWeight: 600,
                          color: '#FFFFFF',
                          marginLeft: 0,
                          marginTop: 0,
                        }}
                      >
                        {index + 1}
                      </Grid>
                      <Card style={{ width: '100%' }}>
                        <CardContent>
                          <Grid item alignSelf={'center'} display={'flex'} mb={2}>
                            <CalendarMonthTwoToneIcon />
                            <Typography className="table-text2" ml={1}>{moment(detail.createdAt).format('L')}</Typography>
                          </Grid>
                          <Typography className="table-text2"><b>Model Name:</b> {detail.model?.model}</Typography>
                          <Typography className="table-text2"><b>Type :</b> {detail.model?.type}</Typography>
                          <Typography className="table-text2"><b>Status:</b> {detail.status}</Typography>
                          <Grid item display={'flex'} justifyContent={'space-between'} mt={2}>
                            <Grid display={'flex'} alignSelf={'center'}>
                              <OpenInFullTwoToneIcon />
                              <Typography className="table-text2" ml={1}>{Number(detail.distance).toFixed(2)} Km</Typography>
                            </Grid>
                            <Grid display={'flex'} alignSelf={'center'}>
                              <TimerTwoToneIcon />
                              <Typography className="table-text2" ml={1}>{this.formatTime(detail.travelTime)}</Typography>
                            </Grid>
                          </Grid>
                          {detail.status === 'new' ? (
                            <Grid style={{ justifyContent: 'center', textAlign: 'center' }}>
                              <Button
                                variant='contained'
                                onClick={() => {
                                  this.props.sendOtp(this.props.login.token, this.props.params.id, detail._id);
                                }}
                              >
                                Send Otp
                              </Button>
                            </Grid>
                          ) : detail.status === 'otp_sent' ? (
                            <Grid style={{ justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                              <Button
                                size='small'
                                variant='contained'
                                sx={{ margin: '2%', backgroundColor: 'rgb(77, 85, 124)' }}
                                onClick={() => {
                                  this.handleLocation();
                                  this.setState({ otp: true, test_id: detail._id });
                                }}
                              >
                                Verify Otp
                              </Button>
                              <Button
                                size='small'
                                sx={{ margin: '2%', color: 'rgb(20, 28, 76)' }}
                                variant='text'
                                onClick={() => {
                                  this.props.sendOtp(this.props.login.token, this.props.params.id, detail._id);
                                }}
                              >
                                Resend Otp
                              </Button>
                            </Grid>
                          ) : (
                            <Grid style={{ justifyContent: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
                              <Button
                                size='small'
                                variant={this.state.visibleMapIndex === index ? 'text' : 'contained'}
                                sx={{ margin: '2%', backgroundColor: this.state.visibleMapIndex === index ? '' : 'rgb(220, 85, 85)', color: this.state.visibleMapIndex === index ? 'rgb(212, 44, 44)' : '#FFFFFF' }}
                                onClick={() => {
                                  this.toggleMapVisibility(index);
                                  this.props.viewTravelRecord(this.props.login.token, detail._id);
                                  this.setState({ mapindex: index, startCoordinates: detail.start_location, endCoordinates: detail.end_location  });
                                }}
                              >
                                {this.state.visibleMapIndex === index ? 'Close Map' : 'Map View'}
                              </Button>
                              {(detail.status !== "end" && detail.status !== "feedback") && (
                                <Button
                                  size='small'
                                  variant='text'
                                  sx={{ margin: '2%', color: 'rgb(212, 44, 44)' }}
                                  onClick={() => {
                                    this.handleLocation();
                                    this.props.endTestDrive(this.props.login.token, this.props.params.id, detail._id, this.state.location.longitude, this.state.location.latitude);
                                  }}
                                >
                                  End Ride
                                </Button>
                              )}
                            </Grid>
                          )}
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                ))}
              </Grid>


              {this.state.visibleMapIndex === this.state.mapindex && (
                <Grid xs={12}>
                    <MapComponent startLocation={this.state.startCoordinates} endLocation={this.state.endCoordinates} />
                    {/* {Array.isArray(this.props.client.drive_record) && this.props.client.drive_record.length > 0 &&
                    <TravelTracker path={this.props.client.drive_record} />
                  } */}
                </Grid>
              )}
            </CardContent>
          </Card>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={add}
            onClose={() => {
              this.setState({
                add: false
              })
            }}
            aria-labelledby="form-dialog-title"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
              <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Select Model</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        labelId="model-select-label"
                        id="model-select"
                        value={model_name}
                        label="Select Model"
                        onChange={(event) => {
                          this.setState({ model_name: event.target.value });
                        }}
                        size='small'
                      >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {Array.isArray(this.props.model.all_model) &&
                          this.props.model.all_model.map((item) => {
                            return (
                              <MenuItem value={item._id}>{item.model}({item.type})</MenuItem>
                            )
                          })
                        }
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button className='table-text2' style={{ border: "0.5px solid rgb(20, 28, 76)", color: "rgb(20, 28, 76)" }}
                onClick={() => {
                  this.setState({ add: false })
                }}
              >
                Cancel
              </Button>
              <Button className='table-text2' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white" }}
                onClick={() => {
                  this.props.addTestDrive(this.props.login.token, this.props.login.user_id, model_name, this.props.params.id)
                  this.setState({ add: false, model_name: '' })
                }}
              >
                Add
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={otp}
            onClose={() => {
              this.setState({
                otp: false
              })
            }}
            aria-labelledby="form-dialog-title"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
              <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>OTP Verification</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} >
                  <Grid container alignItems={'center'}>
                    <Grid item xs={12} >
                      <DialogContentText className='table-text2' fontSize={'20px'}>OTP:</DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="text"
                        fullWidth
                        size='small'
                        placeholder='Enter Otp'
                        onChange={(event) => {
                          this.setState({ otp_no: event.target.value });
                        }}
                        value={otp_no}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button className='table-text2' style={{ border: "0.5px solid rgb(20, 28, 76)", color: "rgb(20, 28, 76)" }}
                onClick={() => {
                  this.setState({ otp: false })
                }}
              >
                Cancel
              </Button>
              <Button className='table-text2' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white" }}
                onClick={() => {
                  this.props.verifyOtp(this.props.login.token, this.props.params.id, this.state.test_id, otp_no, location.longitude, location.latitude)
                  this.setState({ otp: false, otp_no: '' })
                }}
              >
                Verify
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            maxWidth="lg"
            fullWidth
            open={map}
            onClose={() => {
              this.setState({
                map: false
              })
            }}
            aria-labelledby="form-dialog-title"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
              <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Map View</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={0}>
                <MapComponent startLocation={this.props.client.check_start} endLocation={{ type: 'Point', coordinates: [location.longitude, location.latitude] }} />

              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button className='table-text2' style={{ border: "0.5px solid rgb(20, 28, 76)", color: "rgb(20, 28, 76)" }}
                onClick={() => {
                  this.setState({ map: false })
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={edit}
            onClose={() => {
              this.setState({
                edit: false
              })
            }}
            aria-labelledby="form-dialog-title"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "10px",
              },
            }}
          >
            <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
              <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Edit Employee</Typography>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2} mt={0}>
                <Grid item xs={12} >
                  <Grid container alignItems={'center'}>
                    <Grid item xs={12} >
                      <DialogContentText className='table-text2' fontSize={'20px'}>Name:</DialogContentText>
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                        type="text"
                        fullWidth
                        size='small'
                        placeholder='Client Name'
                        onChange={(event) => {
                          this.setState({ name: event.target.value });
                        }}
                        value={name}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={0} spacing={2}>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'}>
                    <Grid item xs={12}>
                      <DialogContentText className='table-text2' fontSize={'20px'}>Phone No.:</DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        type="number"
                        fullWidth
                        size='small'
                        placeholder='Client Phone No.'
                        onChange={(event) => {
                          this.setState({ mobile: event.target.value });
                        }}
                        value={mobile}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container alignItems={'center'}>
                    <Grid item xs={12} >
                      <DialogContentText className='table-text2' fontSize={'20px'}>Email Id :</DialogContentText>
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                        type="email"
                        fullWidth
                        size='small'
                        placeholder='Client Email Id'
                        onChange={(event) => {
                          this.setState({ email: event.target.value });
                        }}
                        value={email}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button className='table-text2' style={{ border: "0.5px solid rgb(20, 28, 76)", color: "rgb(20, 28, 76)" }}
                onClick={() => {
                  this.setState({ edit: false })
                }}
              >
                Cancel
              </Button>
              <Button className='table-text2' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white" }}
                onClick={() => {
                  this.props.updateClient(this.props.login.token, user_id, name, email, mobile, this.props.login.type);
                  this.setState({ edit: false })
                }}
              >
                Save Change
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>

        <Loader />
        <Snackbar
          open={this.props.snackbar.response_received}
          close_snack_bar={this.props.close_snack_bar}
          message={this.props.snackbar.message}
        />
      </Grid>
    );
  }
}
export default withParams(ClientDetails)
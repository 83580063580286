import React, { Component } from "react";

class GoogleFormEmbed extends Component {
  render() {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <h2>Fill Out This Form</h2>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdxqdzpllXP-9GnA3OqGRC_GfzfXK6GFYiEYk8C3PUJKJ9B-g/viewform?embedded=true"
          width="640"
          height="800"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          title="Google Form"
        >
          Loading…
        </iframe>
      </div>
    );
  }
}

export default GoogleFormEmbed;

import * as CONSTANT from "../Executive/Constants.js";
import UNIVERSAL from "../../Config/Config.js";
import { setLoader, unsetLoader } from "../../Common/loader/action.js";
import { set_snack_bar } from "../../Common/snackbar/action.js";
import { onLogout } from "../../Auth/Actions.js";

export function viewExecutive(token, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/view", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        page,
        pageSize,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch({
            type: CONSTANT.EXECUTIVE,
            payload: {
              result: responseJson.data.data,
              count: responseJson.totalPages,
            },
          });
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch({
            type: CONSTANT.EXECUTIVE,
            payload: { result: [], count: 0 },
          });
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function addExecutive(token, name, emp_id, email, mobile, access) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/register", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        name,
        emp_id,
        email,
        mobile,
        access,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch(viewExecutive(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function updateExecutive(
  token,
  user_id,
  name,
  emp_id,
  email,
  mobile,
  access
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/update", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        name,
        emp_id,
        email,
        mobile,
        access,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch(viewExecutive(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteExecutive(token, user_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "user/delete", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch(viewExecutive(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function setExecutiveId(payload) {
  return {
    type: CONSTANT.EXECUTIVE_ID,
    payload: payload,
  };
}

//----------------------------------------view whats app details---------------------------------------//

export function viewWhatsAppSms(token, page, pageSize) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "whatsApp/view_sms", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        page,
        pageSize,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch({
            type: CONSTANT.WHATSAPPSMS,
            payload: {
              result: responseJson.data.result,
              count: responseJson.data.result.length,
            },
          });
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch({
            type: CONSTANT.WHATSAPPSMS,
            payload: { result: [], count: 0 },
          });
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function addWhatsAppSms(
  token,
  user_id,
  name,
  email,
  mobile,
  modal,
  variant,
  color,
  address,
  salesExecutiveName,
  enquirytype,
  message_tempelete
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "whatsApp/add_sms", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        addedBy: user_id,
        name,
        email,
        mobile,
        modal,
        variant,
        color,
        address,
        salesExecutiveName,
        enquirytype,
        message_tempelete,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch(viewWhatsAppSms(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(viewWhatsAppSms(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

export function updateWhatsAppSms(
  token,
  user_id,
  name,
  emp_id,
  email,
  mobile,
  access
) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "whatsApp/update_sms", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
        name,
        emp_id,
        email,
        mobile,
        access,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch(viewWhatsAppSms(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(viewWhatsAppSms(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function deleteWhatsAppSms(token, user_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "whatsApp/delete_sms", {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch(viewWhatsAppSms(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch(viewWhatsAppSms(token, 1, 10));
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}
export function setWhatsAppSmsId(payload) {
  return {
    type: CONSTANT.WHATSAPPSMS_ID,
    payload: payload,
  };
}

export function getmessageTemplete(token, user_id) {
  return (dispatch) => {
    dispatch(setLoader());
    return fetch(UNIVERSAL.BASEURL + "whatsApp/viewmessateTemplete", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify({
        user_id,
      }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);

        if (
          responseJson.statusCode === 201 ||
          responseJson.statusCode === 200
        ) {
          dispatch({
            type: CONSTANT.MESSAGETEMPLETE,
            payload: {
              result: responseJson.data,
            },
          });
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        } else {
          dispatch({
            type: CONSTANT.MESSAGETEMPLETE,
            payload: { result: [] },
          });
          dispatch(unsetLoader());
          dispatch(set_snack_bar(true, responseJson.message));
        }
      })
      .catch((error) => {
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, "Check Your Internet Connection"));
        console.error(error);
      });
  };
}

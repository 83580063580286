import React, { Component } from "react";
import { connect } from "react-redux";
import WhatsAppSms from "../Components/WhatsAppSms";
import { close_snack_bar } from "../../../Common/snackbar/action";
import {
  addWhatsAppSms,
  deleteWhatsAppSms,
  setWhatsAppSmsId,
  updateWhatsAppSms,
  viewWhatsAppSms,
  getmessageTemplete,
} from "../Actions";
export class Controller extends Component {
  render() {
    return <WhatsAppSms {...this.props} />;
  }
}
export const mapStateToProps = (store) => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
    exec: store.exec,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    close_snack_bar: () => {
      dispatch(close_snack_bar());
    },
    viewWhatsAppSms: (token, page, pageSize) => {
      dispatch(viewWhatsAppSms(token, page, pageSize));
    },
    addWhatsAppSms: (
      token,
      user_id,
      name,
      email,
      mobile,
      modal,
      variant,
      color,
      address,
      salesExecutiveName,
      enquirytype,
      message_tempelete
    ) => {
      dispatch(
        addWhatsAppSms(
          token,
          user_id,
          name,
          email,
          mobile,
          modal,
          variant,
          color,
          address,
          salesExecutiveName,
          enquirytype,
          message_tempelete
        )
      );
    },
    updateWhatsAppSms: (
      token,
      user_id,
      id,
      name,
      email,
      mobile,
      modal,
      variant,
      color,
      address,
      salesExecutiveName
    ) => {
      dispatch(
        updateWhatsAppSms(
          token,
          user_id,
          id,
          name,
          email,
          mobile,
          modal,
          variant,
          color,
          address,
          salesExecutiveName
        )
      );
    },
    deleteWhatsAppSms: (token, id) => {
      dispatch(deleteWhatsAppSms(token, id));
    },
    setWhatsAppSmsId: (id) => {
      dispatch(setWhatsAppSmsId(id));
    },
    getmessageTemplete: (token, user_id) => {
      dispatch(getmessageTemplete(token, user_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);

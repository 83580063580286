import * as CONSTANT from "./Constants";
const initial_state = {
  all_exe: [],
  exe_length: 0,
  exec_id: "",
  whatsAppSms_id: "",
  message_tempelete: [],
  sms_length: 0,
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case CONSTANT.EXECUTIVE:
      return (state = {
        ...state,
        all_exe: action.payload.result,
        exe_length: action.payload.count,
      });
    case CONSTANT.EXECUTIVE_ID:
      return (state = { ...state, exec_id: action.payload });
    case CONSTANT.WHATSAPPSMS:
      return (state = {
        ...state,
        all_sms: action.payload.result,
        sms_length: action.payload.count,
      });
    case CONSTANT.WHATSAPPSMS_ID:
      return (state = { ...state, whatsAppSms_id: action.payload });
    case CONSTANT.MESSAGETEMPLETE:
      return (state = { ...state, message_tempelete: action.payload.result });
    default:
      return state;
  }
}
